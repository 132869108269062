<template>
    <loading-spinner v-if="loading" line height="54px" color="secondary"/>
    <v-row class="p-relative" v-else>
        <v-col cols="6" align="right">
            <v-btn color="#FFFFFF" small depressed class="normal-btn" @click="pickFile">
                <v-icon class="mr-1" color="primary" v-text="'$image'"/>
                Subir imagen
            </v-btn>
            <input ref="gallery" class="d-none" type="file" @change="onFileChange" :accept="mimeTypes"/>
        </v-col>
        <div class="separation">o</div>
        <v-col cols="6" align="left">
            <v-btn color="#FFFFFF" small depressed class="normal-btn" @click="takeFile">
                <v-icon class="mr-1" color="primary" v-text="'$camera'"/>
                Tomar una foto
            </v-btn>
            <input ref="camera" class="d-none" type="file" @change="onFileChange" :accept="mimeTypes" capture="user"/>
        </v-col>
    </v-row>
</template>

<script>
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";
export default {
    name: "UploadSignature",
    components: {LoadingSpinner},
    data() {
        return {
            loading: false,
            megabyte: 1048576,
            mimeTypes: 'image/*',
            limitSize: 4 * this.megabyte,
            captureMode: false,
        }
    },
    methods: {
        pickFile() {
            const vm = this
            vm.$refs.gallery.click()
        },
        takeFile() {
            const vm = this
            vm.$refs.camera.click()
        },
        async onFileChange(evt) {
            const vm = this
            const file = evt.target.files[0]
            if (!file) return;
            if (file.size > vm.limitSize) return vm.toast(`Tamaño maximo es de 4mb`, 'error')

            vm.loading = true
            await httpService.postFile('doctor/signature', {
                file: file,
                path: `users/${vm.currentUser.id}/signature`
            })
            vm.loading = false
            vm.$emit('uploaded')
        },
    },
}
</script>

<style scoped>

.separation {
    position: absolute;
    font-weight: bold;
    color: #FFFFFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>