<template>
    <div class="center-all text-center">
        <div>
            <v-img class="mx-auto" width="180" :src="imgLogo"/>
        </div>
        <template v-if="uploaded">
            <div class="title">
                <v-icon v-text="'$check_circle'" size="70" color="#FFFFFF"/>
                <br><br>
                Su firma ha sido correctamente guardada
            </div>
        </template>
        <template v-else>
            <div class="title">
                Haz editado satisfactoriamente las preguntas
                y las opciones de respuesta.
            </div>
            <div class="subtitle">
                Ahora te invitamos toma una fotografía de tu firma
                sobre un papel sin líneas para que esta sea agregada
                a tus órdenes de exámenes.
            </div>
            <div class="my-5">
                <upload-signature @uploaded="uploaded = true"/>
            </div>
        </template>
        <div v-if="uploaded">
            <v-btn color="secondary" x-large depressed class="normal-btn mt-3" @click="goTo({name: 'doctor.invitations'})">
                {{ uploaded ? 'Continuar' : 'Siguiente' }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import UploadSignature from "@/components/doctor/UploadSignature";
export default {
    name: "DoctorSignature",
    components: {UploadSignature},
    data() {
        return {
            uploaded: false,
        }
    },
}
</script>

<style scoped>

.center-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.title {
    font-size: 16px !important;
    line-height: 18px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 32px;
    margin-bottom: 12px;
}

.subtitle {
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
}

</style>